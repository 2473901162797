import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Collapse } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

import CardTariff from '../../components/Companies/Tariff/CardTariff'
import Trial from '../../components/Companies/Tariff/Trial'
import FullAccess from '../../components/Companies/Tariff/FullAccess'
import Individual from '../../components/Companies/Tariff/Individual'

import PresentIcon from '../../../img/icon/PresentIcon.jsx'
import LockIcon from '../../../img/icon/LockIcon.jsx'
import CoolIcon from '../../../img/icon/CoolIcon.jsx'

import R from '../../../services/app/client-server/request.service'

const Tariffs = ({ companyData }) => {
  const tariffSelector = useSelector(state => state.companiesReducer.tariffSelector)
  const statusSelector = useSelector(state => state.companiesReducer.statusSelector)

  const [activeTariff, setActiveTariff] = useState('')

  const [subscription, setSubscription] = useState([])
  const [tariffIds, setTariffIds] = useState([])
  const [tariffs, setTariffs] = useState([])
  const [rates, setRates] = useState([])

  const handleDetailsClick = tariff => {
    setActiveTariff(activeTariff === tariff ? null : tariff)
  }

  useEffect(() => {
    ; (async () => {
      const { data: subscriptionService } = await R.subscription()

      setSubscription(subscriptionService?.data)
    })()
  }, [])

  useEffect(() => {
    ; (async () => {
      const { data: tariff } = await R.tariffIds()
      const tariffIds = tariff?.data.map(tariff => tariff.id)
      setTariffIds(tariffIds)
      setTariffs(tariff.data)
    })()
  }, [])

  useEffect(() => {
    ; (async () => {
      if (tariffIds.length > 0) {
        const tariffsData = []
        for (let i = 0; i < tariffIds.length; i++) {
          const id = tariffIds[i]
          try {
            const { data: tariff } = await R.tariffs(id)

            const formattedTariff = tariff.data.map(t => ({
              description: t.description,
              title: t.title,
              units: t.units,
              price: t.price,
              tariff_id: t.tariff_id,
              threshold: t.threshold,
              order: t.order,
            }))

            tariffsData.push(...formattedTariff)
          } catch (error) {
            console.error(`Ошибка при запросе тарифа с id ${id}:`, error)
          }
        }

        const ratesWithRequired = tariffsData.map(rate => {
          const matchingTariff = tariffs.find(tariff => tariff.id === rate.tariff_id)
          return {
            ...rate,
            is_required: matchingTariff ? matchingTariff.is_required : false,
            post_payment: matchingTariff ? matchingTariff.post_payment : false,
          }
        })

        const sortedRates = ratesWithRequired.sort((a, b) => a.order - b.order)

        setRates(sortedRates)
      }
    })()
  }, [tariffIds, tariffs])

  useEffect(() => {
    switch (tariffSelector) {
      case 1:
        setActiveTariff('trial')
        break
      case 2:
        setActiveTariff('fullAccess')
        break
      case 3:
        setActiveTariff('individual')
        break
      default:
        setActiveTariff(null)
    }
  }, [tariffSelector])

  return (
    <React.Fragment>
      <div
        className='flexContainerWithGap'
        style={{ marginTop: '26px', gap: '16px', justifyContent: 'center' }}
      >
        <CardTariff
          title={'«Пробный период»'}
          chip={statusSelector === 'trial' ? 'Активирован' : 'Завершен'}
          icon={<PresentIcon />}
          price={'1000,00'}
          iconFirstTariff={<CheckCircleIcon sx={{ color: '#22c58b', fontSize: '24px' }} />}
          iconSecondTariff={<CancelIcon sx={{ color: '#ff5963', fontSize: '24px' }} />}
          iconThirdTariff={<CancelIcon sx={{ color: '#ff5963', fontSize: '24px' }} />}
          changeTariff={handleDetailsClick}
          tariffName={'trial'}
          isOpen={activeTariff === 'trial'}
          isTariff={companyData?.subscription_id === 1}
        />

        <CardTariff
          title={'«Полный доступ»'}
          chip={
            statusSelector === 'active'
              ? 'Активирован'
              : statusSelector === 'frozen'
                ? 'Заморожен'
                : 'Не активирован'
          }
          icon={<LockIcon />}
          iconFirstTariff={<CancelIcon sx={{ color: '#ff5963', fontSize: '24px' }} />}
          iconSecondTariff={<CancelIcon sx={{ color: '#ff5963', fontSize: '24px' }} />}
          iconThirdTariff={<CheckCircleIcon sx={{ color: '#22c58b', fontSize: '24px' }} />}
          changeTariff={handleDetailsClick}
          tariffName={'fullAccess'}
          isOpen={activeTariff === 'fullAccess'}
          isTariff={companyData?.subscription_id === 2}
        />

        <CardTariff
          title={'«Индивидуальный»'}
          chip={'Не активирован'}
          icon={<CoolIcon />}
          iconFirstTariff={<CancelIcon sx={{ color: '#ff5963', fontSize: '24px' }} />}
          iconSecondTariff={<CheckCircleIcon sx={{ color: '#22c58b', fontSize: '24px' }} />}
          iconThirdTariff={<CheckCircleIcon sx={{ color: '#22c58b', fontSize: '24px' }} />}
          changeTariff={handleDetailsClick}
          tariffName={'individual'}
          isOpen={activeTariff === 'individual'}
          isTariff={companyData?.subscription_id === 3}
        />
      </div>

      <Collapse in={activeTariff === 'trial'} timeout={500} unmountOnExit>
        <Trial companyData={companyData} rates={rates} subscription={subscription} />
      </Collapse>

      <Collapse in={activeTariff === 'fullAccess'} timeout={500} unmountOnExit>
        <FullAccess companyData={companyData} rates={rates} subscription={subscription} />
      </Collapse>

      <Collapse in={activeTariff === 'individual'} timeout={500} unmountOnExit>
        <Individual companyData={companyData} rates={rates} subscription={subscription} />
      </Collapse>
    </React.Fragment>
  )
}

export default Tariffs
