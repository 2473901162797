// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable no-useless-computed-key */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import dayjs from 'dayjs'
import CachedIcon from '@mui/icons-material/Cached'
import AccordionComponent from '../../../components/LocalElements/Accordion/Accordion.jsx'
import { Checkbox, CircularProgress } from '@mui/material'
import Table from '../../LocalElements/Table/Table'
import Input from '../../../../ui/input/index.tsx'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Button from '../../../../ui/button/index.tsx'
import { refreshCompanies } from '../../../../store/slices/app/controlers/updater'
import R from '../../../../services/app/client-server/request.service'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'

const Individual = props => {
  const { companyData, rates, subscription } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const companyId = useSelector(state => state.companiesReducer.companyId)
  const tariffs = useSelector(state => state.companiesReducer.tariffs)
  const statusSelector = useSelector(state => state.companiesReducer.statusSelector)

  const [subscriptionFee, setSubscriptionFee] = useState('')

  const [prePaymentTotal, setPrePaymentTotal] = useState(0)
  const [postPaymentTotal, setPostPaymentTotal] = useState(0)

  const [startDate, setStartDate] = useState(dayjs())
  const [endDate, setEndDate] = useState(dayjs().add(1, 'month').subtract(1, 'day'))

  const [numberUnits, setNumberUnits] = useState({})
  const [currentOrder, setCurrentOrder] = useState(40)

  const [tariffInclusion, seTariffInclusion] = useState([])
  const [tariffIds, setTariffIds] = useState([])

  useEffect(() => {
    const prePaymentRow = rates.filter(item => !item.post_payment).find(item => item.order === 1)

    if (prePaymentRow) {
      const count = parseInt(numberUnits[1], 10) || 0
      const priseForPrePaymentService = count * (prePaymentRow.price || 0)

      setSubscriptionFee(priseForPrePaymentService.toFixed(2))
    }
  }, [numberUnits, rates])

  const handleInputChange = (id, value) => {
    setNumberUnits(prevState => ({
      ...prevState,
      [id]: value,
    }))
  }

  const filteredData = rates.filter(item => {
    if (item.order < 40 || item.order > 47) {
      return item.post_payment
    }
    return item.post_payment && item.order === currentOrder
  })

  useEffect(() => {
    setEndDate(dayjs(startDate).add(1, 'month').subtract(1, 'day'))
  }, [startDate])

  useEffect(() => {
    if (!companyData?.tariffs || !Array.isArray(companyData.tariffs)) {
      return
    }
    const companyTariffIds = companyData.tariffs.map(tariff => tariff.id)
    const initialInclusion = rates.map(rate => ({
      tariff_id: rate.tariff_id,
      is_required: companyTariffIds.includes(rate.tariff_id) ? true : rate.is_required || false,
    }))
    seTariffInclusion(initialInclusion)
  }, [rates, companyData?.tariffs])

  useEffect(() => {
    const updatedRates = rates.reduce((acc, current) => {
      const existingRate = acc.find(item => item.tariff_id === current.tariff_id)
      if (!existingRate) {
        const matchingState = tariffInclusion.find(state => state.tariff_id === current.tariff_id)

        acc.push({
          tariff_id: current.tariff_id,
          is_required: matchingState ? matchingState.is_required : current.is_required,
        })
      }
      return acc
    }, [])

    setTariffIds(updatedRates)
  }, [rates, tariffInclusion])

  const handleCheckboxChange = (tariffId, isRequired, checked) => {
    seTariffInclusion(prevState => {
      const existingIndex = prevState.findIndex(item => item.tariff_id === tariffId)
      if (existingIndex > -1) {
        const updatedState = [...prevState]
        updatedState[existingIndex] = { tariff_id: tariffId, is_required: checked }
        return updatedState
      } else {
        return [...prevState, { tariff_id: tariffId, is_required: checked }]
      }
    })
  }

  const resetDataAllBillingCount = () => {
    const defaultValues = rates.reduce((acc, item) => {
      acc[item.order] = item.defaultValue || ''
      return acc
    }, {})

    setNumberUnits(defaultValues)
    setCurrentOrder(40)
  }

  useEffect(() => {
    const totalPrePayment = rates
      .filter(item => !item.post_payment)
      .reduce((total, item) => {
        if (item.order === 1) {
          return total
        }
        const count = parseInt(numberUnits[item.order], 10) || 0
        const priseForPrePaymentService = count * (item.price || 0)
        return total + priseForPrePaymentService
      }, 0)

    const totalWithsubscriptionFee = totalPrePayment + (parseFloat(subscriptionFee) ?? 0)

    setPrePaymentTotal(totalWithsubscriptionFee)

    const totalPostPayment = rates
      .filter(item => item.post_payment)
      .reduce((total, item) => {
        const count = parseInt(numberUnits[item.order], 10) || 0
        const priseForPostPaymentService = count * (item.price || 0)
        return total + priseForPostPaymentService
      }, 0)
    setPostPaymentTotal(totalPostPayment)
  }, [rates, numberUnits, subscriptionFee])

  let colDataWithoutActions = [
    {
      field: 'service',
      headerName: 'Услуга',
    },
    {
      field: 'description',
      headerName: 'Описание',
    },
    {
      field: 'billing',
      headerName: 'Биллинг-единица',
    },
    {
      field: 'count',
      headerName: 'Количество единиц',
      renderCell: params => {
        const { row } = params

        if (![1, 2, 3, 4].includes(row.id)) {
          return 'Неограниченно'
        }

        if (row.id === 1 && numberUnits[row.id] !== '1') {
          setNumberUnits(prevState => ({
            ...prevState,
            [1]: '1',
          }))
        }

        return (
          <Input
            placeholder={'Неизвестно'}
            sx={{ height: '44px', border: 'none' }}
            value={numberUnits[row.id] || ''}
            isDisabled={row.id === 1}
            notRequired={true}
            actions={{
              change: value => handleInputChange(row.id, value),
            }}
          />
        )
      },
    },
    {
      field: 'prise',
      headerName: 'Стоимость, ₽ ( за ед. )',
    },
    {
      field: 'priseForService',
      headerName: 'Стоимость услуги, ₽ ( за р.п. )',
    },
  ]

  let colDataWithActions = [
    ...colDataWithoutActions,
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Включить',
      renderCell: params => {
        const tariffId = params.row.tariff_id
        const isRequired = params.row.is_required
        const currentState = tariffInclusion.find(item => item.tariff_id === tariffId)
        return (
          <div style={{ display: 'flex', flexFlow: 'row' }}>
            <Checkbox
              disabled={isRequired}
              checked={isRequired || currentState?.is_required || false}
              onChange={e => handleCheckboxChange(tariffId, isRequired, e.target.checked)}
            />
          </div>
        )
      },
    },
  ]

  const handleSave = async () => {
    const tariffs = tariffIds.filter(rate => rate.is_required).map(rate => rate.tariff_id)

    const data = {
      tariffs,
    }

    const dataActivate = {
      status: 'active',
      subscription_id: 3,
      tariffs,
      ids: [companyId],
      fix_rate_price: parseFloat(subscriptionFee),
    }

    let response
    if (statusSelector === 'trial') {
      response = await R.tariffChangeActivate(dataActivate)
    } else {
      response = await R.tariffChange(data, companyId)
    }

    if (companyData?.subscription_id === 2) {
      await R.changeStatusForActive(companyId, 3)
    }

    const { status, data: tariffsResponse } = response

    if (status === 200) {
      dispatch(setMessage(tariffsResponse.message || 'Изменения успешно сохранены.'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshCompanies())
      navigate('/metriva/companies')
    } else {
      dispatch(setMessage(tariffsResponse.message))
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
    }
  }
  return (
    <React.Fragment>
      <h2 style={{ marginBottom: '16px', fontWeight: 'bold', marginTop: '26px' }}>
        Тариф «{subscription[2]?.title}»
      </h2>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <AccordionComponent
          title='Краткое описание'
          dataType='custom'
          backgroundColor='white'
          data={<span style={{ lineHeight: '24px' }}>{subscription[2]?.description}</span>}
          isExpanded={false}
        />

        <AccordionComponent
          title='Расчетный период'
          dataType='custom'
          backgroundColor='white'
          data={
            <div>
              <h4 style={{ marginBottom: '20px' }}>
                {tariffs.length > 0 ? 'Текущий период' : 'Установление нового периода'}
              </h4>

              <div
                style={{
                  display: 'flex',
                  gap: '16px',
                  flexDirection: tariffs.length > 0 ? 'column' : 'row',
                }}
              >
                {(tariffs.length > 0) ? (
                  <div style={{ display: 'flex', gap: '16px' }}>
                    <p style={{ color: '4B5962' }}>Начало периода :</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {moment(companyData?.licensed_to).format('DD-MM-YYYY')}
                    </p>
                  </div>
                ) : (
                  <DatePicker
                    label={'Начало периода'}
                    selectedDate={startDate}
                    dateChange={newDate => setStartDate(newDate)}
                    minDate={startDate}
                  />
                )}

                {tariffs.length > 0 ? (
                  <div style={{ display: 'flex', gap: '16px' }}>
                    <p style={{ color: '4B5962' }}>Окончание периода (включительно) :</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {moment(companyData?.paid_to).format('DD-MM-YYYY')}
                    </p>
                  </div>
                ) : (
                  <DatePicker
                    label={'Конец (включительно)'}
                    selectedDate={endDate}
                    dateChange={newDate => setEndDate(newDate)}
                    minDate={startDate}
                    isDisabled={true}
                  />
                )}

                {tariffs.length === 0 && (
                  <Input
                    notRequired={true}
                    label={'Предустановленные периоды'}
                    placeholder={'Месяц'}
                    type={'text'}
                    value={'месяц'}
                    isDisabled={true}
                  />
                )}
              </div>
            </div>
          }
          isExpanded={true}
        />

        <AccordionComponent
          title='Набор услуг (предоплата)'
          dataType='custom'
          backgroundColor='white'
          data={
            <>
              <h4>Расчет предоплаты по лицензии</h4>
              <Table
                colData={colDataWithoutActions}
                rowData={rates
                  .filter(item => !item.post_payment)
                  .map(item => {
                    const count = parseInt(numberUnits[item?.order], 10) || 0
                    const priseForPrePaymentService = count * (item?.price || 0)

                    return {
                      id: item?.order,
                      service: item?.title,
                      description: item?.description,
                      billing: item?.units,
                      count: numberUnits[item?.order] || '',
                      prise: item?.price,
                      priseForService: priseForPrePaymentService.toFixed(2),
                      threshold: item?.threshold,
                      is_required: item?.is_required,
                    }
                  })}
                hideFooter={true}
                hasCheckboxSelection={false}
                noRowsText={<CircularProgress />}
                getRowId={row => row.id}
                getRowHeight={() => 'auto'}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 24,
                    },
                  },
                }}
              />
            </>
          }
          isExpanded={true}
        />

        <AccordionComponent
          title='Набор услуг (постоплата)'
          dataType='custom'
          backgroundColor='white'
          data={
            <div
              style={{
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '16px',
                }}
              >
                <h4>Выбор основных опций</h4>

                <Button
                  startIcon={<CachedIcon />}
                  color='text'
                  onClick={resetDataAllBillingCount}
                  label='Обновить данные'
                />
              </div>
              <Table
                rowData={filteredData.map(item => {
                  const count = parseInt(numberUnits[item?.order], 10) || 0
                  const priseForPostPaymentService = count * (item?.price || 0)

                  return {
                    id: item?.order,
                    service: item?.title,
                    description: item?.description,
                    billing: item?.units,
                    count: numberUnits[item?.order] || '',
                    prise: '0.00',
                    priseForService: priseForPostPaymentService.toFixed(2),
                    threshold: item?.threshold,
                    is_required: item?.is_required,
                    tariff_id: item?.tariff_id,
                  }
                })}
                colData={colDataWithActions}
                hideFooter={true}
                hasCheckboxSelection={false}
                getRowHeight={() => 'auto'}
                noRowsText={<CircularProgress />}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 24,
                    },
                  },
                }}
              />
            </div>
          }
          isExpanded={true}
        />

        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '25%', paddingLeft: '10px' }}>
            <h4 style={{ color: '#132532' }}>Действие тарифа :</h4>
            <h4 style={{ color: '#0084e2' }}>
              {companyData?.subscription_id === 3 ? 'Подключен' : 'Не подключен'}
            </h4>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '25%', paddingLeft: '10px' }}>
            <h4 style={{ color: '#132532' }}>Предоплата, ₽ :</h4>
            <h4 style={{ color: '#0084e2' }}>
              {isNaN(prePaymentTotal) ? '0.0' : prePaymentTotal.toFixed(2)}
            </h4>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '25%', paddingLeft: '10px' }}>
            <h4 style={{ color: '#899298' }}>Постоплата, ₽ :</h4>
            <h4 style={{ color: '#0084e2' }}>
              {isNaN(postPaymentTotal) ? '0.0' : postPaymentTotal.toFixed(2)}
            </h4>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '25%', paddingLeft: '10px', marginBottom: '10px' }}>
            <h4 style={{ color: '#899298' }}>Общая стоимость, ₽ :</h4>
            <h4 style={{ color: '#0084e2' }}>
              {isNaN(prePaymentTotal + postPaymentTotal)
                ? '0.0'
                : (prePaymentTotal + postPaymentTotal).toFixed(2)}
            </h4>
          </div>

          <Button
            onClick={handleSave}
            color='primary'
            label={tariffs.length > 0 ? 'Сохранить изменения' : 'Активировать тариф'}
            fullWidth
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Individual
