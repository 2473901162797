// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable no-useless-computed-key */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import dayjs from 'dayjs'
import CachedIcon from '@mui/icons-material/Cached'
import AccordionComponent from '../../../components/LocalElements/Accordion/Accordion.jsx'
import { Checkbox, CircularProgress } from '@mui/material'
import Table from '../../LocalElements/Table/Table'
import Input from '../../../../ui/input/index.tsx'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Button from '../../../../ui/button/index.tsx'
import { refreshCompanies } from '../../../../store/slices/app/controlers/updater'
import R from '../../../../services/app/client-server/request.service'
import {
  setOpen,
  setTitle,
  setChildren,
  setBD,
  showBA,
} from '../../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'

const FullAccess = props => {
  const { companyData, rates, subscription } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const companyId = useSelector(state => state.companiesReducer.companyId)
  const tariffs = useSelector(state => state.companiesReducer.tariffs)
  const statusSelector = useSelector(state => state.companiesReducer.statusSelector)

  const [billingCount, setBillingCount] = useState({})

  const [prePaymentTotal, setPrePaymentTotal] = useState(0)
  const [postPaymentTotal, setPostPaymentTotal] = useState(0)

  const [startDate, setStartDate] = useState(dayjs())
  const [endDate, setEndDate] = useState(dayjs().add(1, 'month').subtract(1, 'day'))

  const [numberUnits, setNumberUnits] = useState({})
  const [currentOrder, setCurrentOrder] = useState(40)
  const [debounceTimer, setDebounceTimer] = useState(null)

  const [tariffInclusion, seTariffInclusion] = useState([])
  const [tariffIds, setTariffIds] = useState([])

  const handleInputChange = (id, value) => {
    const numericValue = value.replace(/\D/g, '')

    setNumberUnits(prevState => {
      const newState = { ...prevState }

      if (id === 1) {
        newState[id] = '1'
      } else {
        newState[id] = numericValue
      }

      if ([11, 12, 20, 50, 60].includes(id)) {
        newState[11] = numericValue
        newState[12] = numericValue
        newState[20] = numericValue
        newState[50] = numericValue
        newState[60] = numericValue
      }

      return newState
    })

    if ([11, 12, 20, 50, 60].includes(id)) {
      setNumberUnits(prevState => {
        const isEmpty =
          !prevState[11] && !prevState[12] && !prevState[20] && !prevState[50] && !prevState[60]

        if (isEmpty) {
          const newState = { ...prevState }
          for (let i = 40; i <= 47; i++) {
            delete newState[i]
          }
          return newState
        }

        return prevState
      })
    }

    if (id >= 40 && id <= 47) {
      if (debounceTimer) {
        clearTimeout(debounceTimer)
      }

      const newTimer = setTimeout(() => {
        setNumberUnits(prevState => {
          if (!prevState[id]) {
            return {
              ...prevState,
              11: '',
              12: '',
              20: '',
              50: '',
              60: '',
            }
          }
          return prevState
        })
      }, 1000)

      setDebounceTimer(newTimer)
    }

    if ([11, 12, 20, 50, 60].includes(id) || (id >= 40 && id <= 47)) {
      if (debounceTimer) {
        clearTimeout(debounceTimer)
      }

      const newTimer = setTimeout(() => {
        const inputValue = parseInt(numericValue, 10)

        if (!isNaN(inputValue) && numericValue !== '') {
          let updatedOrder = currentOrder

          if (inputValue >= 0 && inputValue <= 500) {
            updatedOrder = 40
          } else if (inputValue >= 501 && inputValue <= 1000) {
            updatedOrder = 41
          } else if (inputValue >= 1001 && inputValue <= 1500) {
            updatedOrder = 42
          } else if (inputValue >= 1501 && inputValue <= 2000) {
            updatedOrder = 43
          } else if (inputValue >= 2001 && inputValue <= 3000) {
            updatedOrder = 44
          } else if (inputValue >= 3001 && inputValue <= 5000) {
            updatedOrder = 45
          } else if (inputValue >= 5001 && inputValue <= 7000) {
            updatedOrder = 46
          } else if (inputValue > 7000) {
            updatedOrder = 47
          }

          if (updatedOrder >= 40 && updatedOrder <= 47) {
            setNumberUnits(prevState => {
              const newState = { ...prevState }

              if (currentOrder >= 40 && currentOrder <= 47) {
                delete newState[currentOrder]
              }

              newState[updatedOrder] = numericValue
              newState[11] = numericValue
              newState[12] = numericValue
              newState[20] = numericValue
              newState[50] = numericValue
              newState[60] = numericValue

              return newState
            })
            setCurrentOrder(updatedOrder)
          }
        }
      }, 1000)

      setDebounceTimer(newTimer)
    } else {
      setNumberUnits(prevState => ({
        ...prevState,
        [id]: numericValue,
      }))
    }
  }

  const filteredData = rates.filter(item => {
    if (item.order < 40 || item.order > 47) {
      return item.post_payment
    }
    return item.post_payment && item.order === currentOrder
  })

  const handleCheckboxChange = (tariffId, isRequired, checked) => {
    seTariffInclusion(prevState => {
      const existingIndex = prevState.findIndex(item => item.tariff_id === tariffId)
      if (existingIndex > -1) {
        const updatedState = [...prevState]
        updatedState[existingIndex] = { tariff_id: tariffId, is_required: checked }
        return updatedState
      } else {
        return [...prevState, { tariff_id: tariffId, is_required: checked }]
      }
    })
  }

  const resetDataAllBillingCount = (resetFirstGroup, billingData) => {
    setNumberUnits(prevState => {
      let newState = { ...prevState }

      if (resetFirstGroup) {
        ;[1, 2, 3, 4].forEach(id => {
          newState[id] = rates.find(item => item.order === id)?.defaultValue || ''
        })
      } else {
        if (Object.keys(billingData || {}).length > 0) {
          const updatedNumberUnits = Object.entries(billingData).reduce((acc, [key, value]) => {
            if (value?.order !== undefined && value?.count !== undefined) {
              acc[value.order] = value.count.toString()
            }
            return acc
          }, {})

          newState = {
            ...prevState,
            ...updatedNumberUnits,
          }

          Object.keys(newState).forEach(id => {
            if (!updatedNumberUnits[id] && Number(id) > 4) {
              delete newState[id]
            }
          })
        } else {
          newState = Object.keys(prevState).reduce((acc, key) => {
            if ([1, 2, 3, 4].includes(Number(key))) {
              acc[key] = prevState[key]
            }
            return acc
          }, {})
        }

        setCurrentOrder(40)
      }

      return newState
    })

    setTimeout(() => {
      fetchBillingData()
    }, 100)
  }

  useEffect(() => {
    setEndDate(dayjs(startDate).add(1, 'month').subtract(1, 'day'))
  }, [startDate])

  useEffect(() => {
    if (!companyData?.tariffs || !Array.isArray(companyData.tariffs)) {
      return
    }
    const companyTariffIds = companyData.tariffs.map(tariff => tariff.id)
    const initialInclusion = rates.map(rate => ({
      tariff_id: rate.tariff_id,
      is_required: companyTariffIds.includes(rate.tariff_id) ? true : rate.is_required || false,
    }))
    seTariffInclusion(initialInclusion)
  }, [rates, companyData?.tariffs])

  useEffect(() => {
    const updatedRates = rates.reduce((acc, current) => {
      const existingRate = acc.find(item => item.tariff_id === current.tariff_id)
      if (!existingRate) {
        const matchingState = tariffInclusion.find(state => state.tariff_id === current.tariff_id)

        acc.push({
          tariff_id: current.tariff_id,
          is_required: matchingState ? matchingState.is_required : current.is_required,
        })
      }
      return acc
    }, [])

    setTariffIds(updatedRates)
  }, [rates, tariffInclusion])

  useEffect(() => {
    const totalPrePayment = rates
      .filter(item => !item.post_payment)
      .reduce((total, item) => {
        const count = parseInt(numberUnits[item.order], 10) || 0
        const priseForPrePaymentService = count * (item.price || 0)
        return total + priseForPrePaymentService
      }, 0)
    setPrePaymentTotal(totalPrePayment)

    const totalPostPayment = rates
      .filter(item => item.post_payment)
      .reduce((total, item) => {
        const count = parseInt(numberUnits[item.order], 10) || 0
        const priseForPostPaymentService = count * (item.price || 0)
        return total + priseForPostPaymentService
      }, 0)
    setPostPaymentTotal(totalPostPayment)
  }, [rates, numberUnits])

  let colDataWithoutActions = [
    {
      field: 'service',
      headerName: 'Услуга',
    },
    {
      field: 'description',
      headerName: 'Описание',
    },
    {
      field: 'billing',
      headerName: 'Биллинг-единица',
      renderCell: params => {
        const { value } = params

        const billingText =
          value === 'Протокол' ? `${value} <span style="color: #0084e2">*</span>` : value
        return <div dangerouslySetInnerHTML={{ __html: billingText }} />
      },
    },
    {
      field: 'count',
      headerName: 'Количество единиц',
      renderCell: params => {
        const { row } = params

        if (row.id === 1 && numberUnits[row.id] !== '1') {
          setNumberUnits(prevState => ({
            ...prevState,
            [1]: '1',
          }))
        }

        return (
          <Input
            placeholder={'Неизвестно'}
            sx={{ height: '44px', border: 'none' }}
            value={numberUnits[row.id] || ''}
            isDisabled={row.id === 1}
            notRequired={true}
            actions={{
              change: value => handleInputChange(row.id, value),
            }}
          />
        )
      },
    },
    {
      field: 'prise',
      headerName: 'Стоимость, ₽ ( за ед. )',
      renderCell: params => {
        const { row } = params
        const countBillingValue = row.billing

        let priceToShow = row.prise

        if (row.threshold !== null && row.threshold !== undefined) {
          switch (true) {
            case countBillingValue >= 501 && countBillingValue <= 1000:
              priceToShow = '13.50'
              break
            case countBillingValue >= 1001 && countBillingValue <= 1500:
              priceToShow = '12.00'
              break
            case countBillingValue >= 1501 && countBillingValue <= 2000:
              priceToShow = '10.50'
              break
            case countBillingValue >= 2001 && countBillingValue <= 3000:
              priceToShow = '9.00'
              break
            case countBillingValue >= 3001 && countBillingValue <= 5000:
              priceToShow = '7.50'
              break
            case countBillingValue >= 5001 && countBillingValue <= 7000:
              priceToShow = '5.25'
              break
            case countBillingValue >= 7001:
              priceToShow = '4.00'
              break
            default:
              priceToShow = row.prise
          }
        }

        return (
          <div>
            {priceToShow}
            {row.threshold !== null && row.threshold !== undefined && (
              <span style={{ color: '#0084e2' }}> **</span>
            )}
          </div>
        )
      },
    },
    {
      field: 'priseForService',
      headerName: 'Стоимость услуги, ₽ ( за р.п. )',
    },
  ]

  let colDataWithActions = [
    ...colDataWithoutActions,
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Включить',
      renderCell: params => {
        const tariffId = params.row.tariff_id
        const isRequired = params.row.is_required
        const currentState = tariffInclusion.find(item => item.tariff_id === tariffId)
        return (
          <div style={{ display: 'flex', flexFlow: 'row' }}>
            <Checkbox
              disabled={isRequired}
              checked={isRequired || currentState?.is_required || false}
              onChange={e => handleCheckboxChange(tariffId, isRequired, e.target.checked)}
            />
          </div>
        )
      },
    },
  ]

  const fetchBillingData = async () => {
    const { data: billingCountResponse } = await R.getBillingCount(`${companyId}`)
    const newBillingData = billingCountResponse.data || {}

    setNumberUnits(prevState => {
      const updatedNumberUnits = Object.entries(newBillingData).reduce((acc, [key, value]) => {
        if (value?.order !== undefined && value?.count !== undefined) {
          acc[value.order] = value.count.toString()
        }
        return acc
      }, {})

      return {
        ...prevState,
        ...updatedNumberUnits,
      }
    })
  }

  useEffect(() => {
    fetchBillingData()
  }, [companyId])

  const handleSave = async () => {
    const tariffs = tariffIds.filter(rate => rate.is_required).map(rate => rate.tariff_id)

    const data = {
      tariffs,
    }

    const dataActivate = {
      status: 'active',
      subscription_id: 2,
      tariffs,
      ids: [companyId],
    }

    let response
    if (statusSelector === 'trial') {
      response = await R.tariffChangeActivate(dataActivate)
    } else {
      response = await R.tariffChange(data, companyId)
    }

    if (companyData?.subscription_id === 3) {
      await R.changeStatusForActive(companyId, 2)
    }

    const { status, data: tariffsResponse } = response

    if (status === 200) {
      dispatch(setMessage(tariffsResponse.message || 'Изменения успешно сохранены.'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshCompanies())
      navigate('/metriva/companies')
    } else {
      dispatch(setMessage(tariffsResponse.message))
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
    }
  }

  return (
    <React.Fragment>
      <h2 style={{ marginBottom: '16px', fontWeight: 'bold', marginTop: '26px' }}>
        Тариф «{subscription[1]?.title}»
      </h2>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <AccordionComponent
          title='Краткое описание'
          dataType='custom'
          backgroundColor='white'
          data={<span style={{ lineHeight: '24px' }}>{subscription[1]?.description}</span>}
          isExpanded={false}
        />

        <AccordionComponent
          title='Расчетный период'
          dataType='custom'
          backgroundColor='white'
          data={
            <div>
              <h4 style={{ marginBottom: '20px' }}>
                {tariffs.length > 0 ? 'Текущий период' : 'Установление нового периода'}
              </h4>

              <div
                style={{
                  display: 'flex',
                  gap: '16px',
                  flexDirection: tariffs.length > 0 ? 'column' : 'row',
                }}
              >
                {(tariffs.length > 0) ? (
                  <div style={{ display: 'flex', gap: '16px' }}>
                    <p style={{ color: '4B5962' }}>Начало периода :</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {moment(companyData?.licensed_to).format('DD-MM-YYYY')}
                    </p>
                  </div>
                ) : (
                  <DatePicker
                    label={'Начало периода'}
                    selectedDate={startDate}
                    dateChange={newDate => setStartDate(newDate)}
                    minDate={startDate}
                  />
                )}

                {tariffs.length > 0 ? (
                  <div style={{ display: 'flex', gap: '16px' }}>
                    <p style={{ color: '4B5962' }}>Окончание периода (включительно) :</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {moment(companyData?.paid_to).format('DD-MM-YYYY')}
                    </p>
                  </div>
                ) : (
                  <DatePicker
                    label={'Конец (включительно)'}
                    selectedDate={endDate}
                    dateChange={newDate => setEndDate(newDate)}
                    minDate={startDate}
                    isDisabled={true}
                  />
                )}

                {tariffs.length === 0 && (
                  <Input
                    notRequired={true}
                    label={'Предустановленные периоды'}
                    placeholder={'Месяц'}
                    type={'text'}
                    value={'месяц'}
                    isDisabled={true}
                  />
                )}
              </div>
            </div>
          }
          isExpanded={true}
        />

        <AccordionComponent
          title='Набор услуг (предоплата)'
          dataType='custom'
          backgroundColor='white'
          data={
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '16px',
                }}
              >
                <h4>Расчет предоплаты по лицензии</h4>
                <Button
                  startIcon={<CachedIcon />}
                  color='text'
                  onClick={() => resetDataAllBillingCount(true, billingCount)}
                  label='Обновить данные'
                />
              </div>
              <Table
                colData={colDataWithoutActions}
                rowData={rates
                  .filter(item => !item.post_payment)
                  .map(item => {
                    const count = parseInt(numberUnits[item?.order], 10) || 0
                    const priseForPrePaymentService = count * (item?.price || 0)

                    return {
                      id: item?.order,
                      service: item?.title,
                      description: item?.description,
                      billing: item?.units,
                      count: numberUnits[item?.order] || '',
                      prise: item?.price,
                      priseForService: priseForPrePaymentService.toFixed(2),
                      threshold: item?.threshold,
                      is_required: item?.is_required,
                    }
                  })}
                hideFooter={true}
                hasCheckboxSelection={false}
                noRowsText={<CircularProgress />}
                getRowId={row => row.id}
                getRowHeight={() => 'auto'}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 24,
                    },
                  },
                }}
              />
            </>
          }
          isExpanded={true}
        />

        <AccordionComponent
          title='Набор услуг (постоплата)'
          dataType='custom'
          backgroundColor='white'
          data={
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '16px',
                }}
              >
                <h4>Выбор основных опций</h4>

                <Button
                  startIcon={<CachedIcon />}
                  color='text'
                  onClick={() => resetDataAllBillingCount(false, billingCount)}
                  label='Обновить данные'
                />
              </div>
              <Table
                rowData={filteredData.map(item => {
                  const count = parseInt(numberUnits[item?.order], 10) || 0
                  const priseForPostPaymentService = count * (item?.price || 0)

                  return {
                    id: item?.order,
                    service: item?.title,
                    description: item?.description,
                    billing: item?.units,
                    count: numberUnits[item?.order] || '',
                    prise: item?.price,
                    priseForService: priseForPostPaymentService.toFixed(2),
                    threshold: item?.threshold,
                    is_required: item?.is_required,
                    tariff_id: item?.tariff_id,
                  }
                })}
                colData={colDataWithActions}
                hideFooter={true}
                hasCheckboxSelection={false}
                getRowHeight={() => 'auto'}
                noRowsText={<CircularProgress />}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 24,
                    },
                  },
                }}
              />
              <div style={{ marginTop: '16px' }}>
                <p style={{ paddingLeft: '10px' }}>
                  <span style={{ color: '#0084e2' }}>*</span> - тарифицируются только протоколы
                  отправленные во ФГИС Аршин и ФСА
                </p>
                <p style={{ paddingLeft: '10px' }}>
                  <span style={{ color: '#0084e2' }}>**</span> - стоимость может меняться в
                  зависимости от числа поверок{' '}
                  <span
                    style={{
                      color: '#0084e2',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      dispatch(setOpen(true))
                      dispatch(setTitle('Стоимость может меняться в зависимости от числа поверок'))

                      dispatch(
                        setChildren(
                          <div>
                            <p style={{ marginBottom: '8px' }}>
                              До 500 поверок в месяц - 15,00 ₽/шт.
                            </p>
                            <p style={{ marginBottom: '8px' }}>
                              От 501 до 1000 поверок в месяц - 13,50 ₽/шт.
                            </p>
                            <p style={{ marginBottom: '8px' }}>
                              От 1001 до 1500 поверок в месяц - 12,00 ₽/шт.
                            </p>
                            <p style={{ marginBottom: '8px' }}>
                              От 1501 до 2000 поверок в месяц - 10,50 ₽/шт.
                            </p>
                            <p style={{ marginBottom: '8px' }}>
                              От 2001 до 3000 поверок в месяц - 9,00 ₽/шт.
                            </p>
                            <p style={{ marginBottom: '8px' }}>
                              От 3001 до 5000 поверок в месяц - 7,50 ₽/шт.
                            </p>
                            <p style={{ marginBottom: '8px' }}>
                              От 5001 до 7000 поверок в месяц - 5,25 ₽/шт.
                            </p>
                            <p>Более 7001 поверок в месяц - 4,00 ₽/шт.</p>
                          </div>
                        )
                      )
                      dispatch(setBD('Cкрыть'))
                      dispatch(showBA(false))
                    }}
                  >
                    см. подробнее
                  </span>
                </p>
              </div>
            </>
          }
          isExpanded={true}
        />

        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '25%', paddingLeft: '10px' }}>
            <h4 style={{ color: '#132532' }}>Действие тарифа :</h4>
            <h4 style={{ color: '#0084e2' }}>
              {companyData?.subscription_id === 2 ? 'Подключен' : 'Не подключен'}
            </h4>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '25%', paddingLeft: '10px' }}>
            <h4 style={{ color: '#132532' }}>Предоплата, ₽ :</h4>
            <h4 style={{ color: '#0084e2' }}>{prePaymentTotal.toFixed(2)}</h4>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '25%', paddingLeft: '10px' }}>
            <h4 style={{ color: '#899298' }}>Постоплата, ₽ :</h4>
            <h4 style={{ color: '#0084e2' }}>{postPaymentTotal.toFixed(2)}</h4>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '25%', paddingLeft: '10px', marginBottom: '10px' }}>
            <h4 style={{ color: '#899298' }}>Общая стоимость, ₽ :</h4>
            <h4 style={{ color: '#0084e2' }}>{(prePaymentTotal + postPaymentTotal).toFixed(2)}</h4>
          </div>

          <Button
            onClick={handleSave}
            color='primary'
            label={tariffs.length > 0 ? 'Сохранить изменения' : 'Активировать тариф'}
            fullWidth
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default FullAccess
